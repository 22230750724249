import React from 'react'
import { Container } from '@components'
import { AboutPage } from '@pages/components'
import { useWindowSize } from '@hooks'
import { Button, Link } from '@elements'
import ArrowLeft from '@images/arrow-nav-left.svg'
import ArrowRight from '@images/arrow-nav-right.svg'

interface ConditionGuideProps {}

const renderDesktopNav = () => {
  return (
    <div className="banner-guide-nav">
      <div className={'banner-guide__button'}>
        <Link to={'/about/buying/'}>
          <>
            <ArrowLeft /> Buying Guide
          </>
        </Link>
      </div>

      <div className={'banner-guide__button'}>
        <Link to={'/about/selling/'}>
          <>
            Selling Guide <ArrowRight />
          </>
        </Link>
      </div>
    </div>
  )
}

const renderMobileNav = () => {
  return (
    <div className="banner-guide-nav">
      <Button to={'/about/buying/'} type="roundBlack">
        Buying Guide
      </Button>

      <Button to={'/about/selling/'} type="roundBlack">
        Selling Guide
      </Button>
    </div>
  )
}

const ConditionGuide: React.FC<ConditionGuideProps> = () => {
  const { toRender: isMobileRender } = useWindowSize(['mobile', 'landscape'])
  return (
    <Container>
      <div className={'banner-guide'}>
        <div className="banner-guide__title"> Condition Guide </div>
        <div className="banner-guide__description">If you are shipping a golf club here are some tips for packing.</div>

        {!isMobileRender && renderDesktopNav()}
        {isMobileRender && renderMobileNav()}
      </div>
      <AboutPage.ConditionGuide />
    </Container>
  )
}

export default ConditionGuide
